import React from 'react';
import ReactDOM from 'react-dom';

function noti(e) {
      e.preventDefault();
      console.log(e);
}

function dateToString1(date) {
      return `1번: ${date.toLocaleTimeString()}`;
}

function dateToString2(date) {
      return `2번: ${date.toLocaleTimeString()}`;
}

let numObj = {
      "a": 1,
      "b": 2,
      "c": 3
}

class Clock extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  date: new Date(),
                  random: Math.floor(Math.random() * 1)
            };

            this.noti = this.noti.bind(this);
      }

      componentDidMount() {
            this.timerID = setInterval(
                  () => this.tick(),
                  1000
            );
      }

      componentWillUnmount() {
            clearInterval(this.timerID);
      }

      tick() {
            this.setState({
                  date: new Date(),
                  random: Math.floor(Math.random() * 11 - 5)
            });
      }

      noti(e) {
            e.preventDefault();
            console.log("Inner");
            console.log(e);
      }

      numlist() {
            return Object.entries(numObj).map(([key, value]) => (
                  <li key={key.toString()}>
                        <span>{key}</span>: {value}
                  </li>
            ));
      }

      render() {
            return (
                  <div className="timerBox">
                        <h1 onClick={noti}>Hello, {this.props.name}!</h1>
                        <h2 onClick={this.noti.bind()}>It is {
                              (this.state.random <= 0) ?
                                    dateToString1(this.state.date)
                                    :
                                    dateToString2(this.state.date)
                        } now.</h2>
                        <h3>{this.state.random <= 0 ? '음' : '양'}수</h3>
                        <ul>
                              {this.numlist()}
                        </ul>
                  </div>
            );
      }
}

class Main extends React.Component {
      constructor(props) {
            super(props);
      }

      render() {
            return (
                  <div>
                        <Clock name={this.props.name1} />
                        <Clock name={this.props.name2} />
                  </div>
            )
      }
}

class Input extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  value: {}
            };

            this.handleChange = this.handleChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
      }


      handleChange(e) {
            let value = this.state.value;
            let thisValue = e.target.value;

            if (e.target.multiple) {
                  thisValue = Array.from(e.target.selectedOptions, option => option.value);
            } else if (e.target.type == 'checkbox') {
                  thisValue = e.target.checked ? true : false;
            } else {
                  thisValue = e.target.value;
            }

            value[e.target.name] = thisValue;

            this.setState({
                  value: value
            });
      }

      handleSubmit(e) {
            console.log(this.state.value);
            e.preventDefault();
      }

      render() {
            return (
                  <form onSubmit={this.handleSubmit}>
                        <select multiple name="sel1" onChange={this.handleChange}>
                              <option value="grapefruit">Grapefruit</option>
                              <option value="lime">Lime</option>
                              <option value="coconut">Coconut</option>
                              <option value="mango">Mango</option>
                        </select>
                        <input type="text" name="val1" onChange={this.handleChange} />
                        <input type="text" name="val2" onChange={this.handleChange} />
                        <input type="checkbox" name="chk1" onChange={this.handleChange} />
                        <textarea name="tarea1" onChange={this.handleChange} />
                        <button type="submit">확인</button>
                  </form>
            )
      }
}

class Son extends React.Component {
      constructor(props) {
            super(props);

            this.handleChange = this.handleChange.bind(this);
      }

      handleChange(e) {
            this.props.onMomChange(e.target.value);
      }

      render() {
            const sonVal = this.props.momValue;

            return (
                  <input type="number" value={sonVal} onChange={this.handleChange} />
            )
      }
}

class Mom extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  momValue: "",
                  mod: 1
            };

            this.handleChange1 = this.handleChange1.bind(this);
            this.handleChange2 = this.handleChange2.bind(this);
      }

      handleChange1(inValue) {
            this.setState({
                  momValue: inValue,
                  mod: 1
            });
      }

      handleChange2(inValue) {
            this.setState({
                  momValue: inValue,
                  mod: 2
            });
      }

      render() {
            const val = this.state.momValue;
            const mod = this.state.mod;
            const thisVal = mod == 2 ? val * 100 : val;
            const thisVal2 = mod == 1 ? val * 0.01 : val;

            return (
                  <div>
                        <Son momValue={thisVal} onMomChange={this.handleChange1} />
                        <Son momValue={thisVal2} onMomChange={this.handleChange2} />
                  </div>
            )
      }
}

class App extends React.Component {
      render() {
            return (
                  <React.Fragment>
                        <Main name1="A" name2="B" />
                        <Input />
                        <Mom />
                  </React.Fragment>
            )
      }      
}

export default App;